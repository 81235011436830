<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          elevation="0"
        >
          <v-btn
            icon
            dark
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="onClose"
            >
              {{$t('common.close', locale)}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row
          v-if="editedItem"
          style="margin-top: 0px;padding: 30px;background-color:#CCCCCC80;"
        >
          <v-col
            cols="12"
            sm="6"
            style="padding: 0 10px 0 10px;"
          >
            <v-row>
              <v-col
                cols="12"
                sm="12"
                style="padding: 0 10px 0 10px;"
              >
                <v-text-field
                  label="Nombre"
                  v-model="editedItem.Name"
                  hide-details
                  dense
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                sm="3"
                style="padding: 10px 10px 10px 0px;"
              >
                <v-text-field
                  label="Width"
                  v-model="editedItem.Width"
                  hide-details
                  dense
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                sm="3"
                style="padding: 10px 0px 10px 10px;"
              >
                <v-text-field
                  label="Height"
                  v-model="editedItem.Height"
                  hide-details
                  dense
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            style="padding: 0 0 0 30px;"
          >
            <select-image 
              :image="editedItem.BackgroundImage"
              :onChange="handleChangeBackground"
              :locale="locale"
              :imageWidth="editedItem.Width"
              :imageHeight="editedItem.Height"
              :aspectRatio="editedItem.Width/editedItem.Height"
              :folder="workspaceID"
              style="width:100%;"
            />
          </v-col>
          <v-col
            v-if="editedItem.ID"
            cols="12"
            sm="12"
            style="margin: 20px;padding: 30px;background-color: white;border-radius: 5px;"
          >
            <center><b>Campos de plantilla</b></center>
            <br />
            <field-form 
              :editedID="editedItem.ID"
              :locale="locale"
              :workspaceID="workspaceID"
            />
          </v-col>
          <v-col
            v-else
            cols="12"
            sm="12"
            style="padding: 10px 23px 0 0;"
          >
            TODO: guardar antes de poder añadir campos
          </v-col>
          <v-col
            cols="12"
            sm="12"
            style="padding: 10px 23px 0 0;"
          >
            <v-textarea
              label="CSS"
              v-model="editedItem.CSS"
              hide-details
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            style="padding: 10px 23px 0 0;"
          />
          <v-col
            cols="12"
            sm="6"
            style="padding: 10px 23px 0 0;"
          >
            <action-buttons
              v-if="editedItem"
              :id="editedItem.ID"
              :onDeleteItem="handleDelete"
              :onSaveItem="handleSave"
              :onClose="onClose"
              :locale="locale"
              :icon="false"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
const defaultItem = {
  Name: '',
  Width: 1920,
  Height: 1080,
  Background: null,
}
import api from '@/services/api'
import SelectImage from '@/components/selectImage/Index'
import ActionButtons from '@/components/ActionButtons'
import FieldForm from './FieldForm'
export default {
  components: {
    SelectImage,
    ActionButtons,
    FieldForm,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    customContentTypeID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,
  }),
  computed: {
    languages () {
      return ['es', 'en', 'de', 'fr', 'ca', 'it']
    },
  },  
  watch: {
    customContentTypeID (v) {
      this.handleGetData()
      this.dialog = v
    },
  },
  methods: {
    handleGetData () {
      this.editedItem = null
      if (!this.customContentTypeID) return

      if (this.customContentTypeID === 'new') {
        this.editedItem = JSON.parse(JSON.stringify(defaultItem))
        this.editedItem.WorkspaceID = this.workspaceID
      } else {
        api.getItem ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types/`, this.customContentTypeID)
          .then(response => {
            if (!response) {
              return
            }
            this.editedItem = response
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    handleChangeBackground (v) {
      this.editedItem.BackgroundImage = v.base64
      this.editedItem.newBackground = v
    },
    handleSave () {
      if (!this.editedItem.ID) {
        api.addItem ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types`, this.editedItem)
          .then(response => {
            if (!response || !response.data || !response.data.data) return
            this.editedItem = response.data.data
          })
          .catch(error => {
            alert(error)
          })
      } else {
        api.updateItem ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types/`, this.editedItem.ID, this.editedItem)
          .then(response => {
            console.log(response)
            this.onClose(true)
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    handleDelete () {
      api.deleteItem ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types/`, this.editedItem.ID)
        .then(response => {
          console.log(response)
          this.onClose(true)
        })
        .catch(error => {
          alert(error)
        })
    },
  }
}
</script>
