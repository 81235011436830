<template>
  <v-layout wrap>
    <v-flex xs8 style="padding: 20px 10px 20px 20px">
      <custom-table
        :loading="loading"
        :title="$t('workspace.title', locale)"
        :headers="[
          { text: $t('workspace.name', locale), value: 'ident' },
          { text: $t('workspace.users', locale), value: 'users' },
        ]"
        :onGetData="handleGetData"
        :tableData="workspaces"
        :onEditItem="handleEdit"
        :onDeleteItem="handleDelete"
        :startIndex="startIndex"
        :itemsPerPage="itemsPerPage"
        :count="count"
        source="dviewcontentWorkspace"
        :onSearch="handleSearch"
        :hideAdd="true"
      />
    </v-flex>
    <v-flex xs4 style="padding: 20px 20px 20px 10px">
      <v-card
        style="min-height:calc(100vh - 104px);"
        elekvation="0"
      >
        <v-card-text>
          <v-btn
            v-if="!showForm"
            block
            color="primary"
            @click="handleAdd"
          >
            {{ $t('common.add', locale) }}
          </v-btn>
          <workspace-form
            :editedID="editedID"
            :showForm="showForm"
            :onDelete="handleDelete"
            :onSave="handleSave"
            :onClose="handleCloseForm"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  const defaultItem = {
    Name: '',
    Users: [],
    PrimaryColor: null,
    SecondaryColor: null,
  }
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import CustomTable from '@/components/customTable/Index'
  import WorkspaceForm from './WorkspaceForm'
  export default {
    components: {
      CustomTable,
      WorkspaceForm
    },
    data: () => ({
      loading: false,
      searchValue: '',
      workspaces: [],
      startIndex: 0,
      itemsPerPage: 5,
      count: null,
      editedID: null,
      showForm: null
    }),
    computed: {
      ...mapState('app',['locale']),
    },
    mounted () {
      this.handleGetData()
    },
    methods: {
      handleGetData (startIndex) {
        this.workspaces = []
        this.loading = true
        if (startIndex >=0 ) this.startIndex = startIndex
        api.getAll ('dviewcontent', 'v1/system/workspaces/', this.startIndex, this.itemsPerPage, this.searchValue)
        .then(response => {             
          this.count = response.count
          this.workspaces = response.data
          this.loading = false
        })
      },
      handleGetItem (workspaceID) {
        this.editeWorkspaceID = 
        api.getItem ('dviewcontent', 'v1/system/workspaces/', workspaceID)
        .then(response => {   
          this.editedItem = response
          this.showForm = true
        })
      },
      handleAdd () {
        this.handleEdit(null)
      },
      handleEdit (v) {
        this.editedID = v
        this.showForm = true
        if (v) this.handleGetItem(v)
        else {
          this.editedItem = JSON.parse(JSON.stringify(defaultItem))
          this.showForm = true
        }
      },
      handleDelete (v) {
        if (v) 
          api.deleteItem ('dviewcontent', 'v1/system/workspaces/', v)
          .then(() => {                      
            this.handleGetData(this.startIndex)
            this.handleCloseForm()
          })
      },
      handleSave (v) {
        if (v.ID)
          api.updateItem ('dviewcontent', 'v1/system/workspaces/', v.ID, v)
          .then(() => {          
            this.handleGetData(this.startIndex)
            this.handleCloseForm()
          })  
        else
          api.addItem ('dviewcontent', 'v1/system/workspaces/', v)
          .then(() => {          
            this.handleGetData(this.startIndex)
            this.handleCloseForm()
          })        
      },
      handleCloseForm () {
        this.showForm = false
      },
      handleSearch (v) {
        this.searchValue = v
        this.handleGetData (0)
      },
    }
  }
</script>
